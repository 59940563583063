export default {
	properties: [
		{
			text: 'Nombre',
			key: 'name',
			type: 'text',
			value: '',
			is_title: true,
		},
		{
			text: 'Ocupacion',
			key: 'ocupation',
			type: 'text',
			value: '',
			show: true,
		},
	],
}