export default {
	properties: [
		{
			text: 'Nombre',
			key: 'name',
			type: 'text',
			value: '',
			is_title: true,
		},
		{
			text: 'Condicion frente al IVA',
			key: 'iva_condition_id',
			type: 'select',
			value: 0
		},
	],
}